<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 57%;display: flex;">
					<el-input class="hunt" v-model="monicker" placeholder="请输入会员姓名" size="large" @input="orangutan" clearable />
					<el-input class="hunt" v-model="encipher" placeholder="请输入会员编码" size="large" @input="legs" clearable />
					<el-select class="hunt" v-model="ability" placeholder="请选择组织名称" size="large" @click="multiple"
						clearable @change="numerical" ref="selectIt" @focus="closeIt" />
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="file"> <img
							src="../assets/guidang.png" style="width:18px;margin-right: 6px" alt=""> 归档</el-button>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="guide"><img
							src="../assets/daoru.png" style="width:25px;margin-right: 6px" alt="">导入</el-button>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="payout"><img
							src="../assets/daochu.png" style="width:15px;margin-right: 6px" alt="">导出</el-button>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="append"><el-icon
							class="cancelgu">
							<Plus />
						</el-icon>添加</el-button>
				</div>
			</div>
			<div class="hint">统计 : {{altogether}}人</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 会员姓名 -->
				<el-table-column align="center" :key="name" label="会员姓名" prop="name" :show-overflow-tooltip='true' />
				<!-- 会员编号 -->
				<el-table-column label="会员编码" prop="username" :key="username" align="center"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 所属工会 -->
				<el-table-column label="所属工会" prop="topUnionName" :key="topUnionName" align="center"
					:formatter="quantum" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 所属部门 -->
				<el-table-column label="所属部门" :key="parentUnionName" prop="parentUnionName" align="center"
					:formatter="quantum" :show-overflow-tooltip='true'>
				</el-table-column>
				<el-table-column label="操作" align="center" :width="330" :show-overflow-tooltip='true'>
					<template #default="scope">
						<el-button size="default" @click.stop="unit(scope.row.userId)" class="bulur"><el-icon
								class="cancel">
								<Search />
							</el-icon>查看</el-button>
						<el-button size="default" @click.stop="copyreader(scope.row.userId)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
						<el-button size="default" @click.stop="expurgate(scope.row.userId)" class="red"><el-icon
								class="cancel">
								<Delete />
							</el-icon>离职归档</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				v-model:currentPage="currentPage" :page-size="pagesize" :total="altogether"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</el-card>
		<!-- 删除按钮弹窗 -->
		<el-dialog v-model="remove" title="离职归档" width="25%" center>
			<div class="labor">
				<div class="sure">是否归档？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" size="large" @click="remove=false">取消</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>

		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>

	</div>
</template>
<script>
	import {
		Delete,
		EditPen,
		Search,
		Plus
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		Hylist,
		Eonhole,
		Zzlist,
		HYtotal
	} from "../utils/api";
	import {
		ref,
		onMounted,
		onDeactivated,
		onActivated,
		nextTick,
	} from "vue";
	import {
		ElMessage
	} from "element-plus";
	import qs from "qs"
	export default {
		name: "member",

		setup() {
			// 弹窗提示内容
			let quoth = ref('')
			// 下载提示弹窗
			let anew = ref(false)
			// 导出功能  
			const payout = () => {
				let data = {
					// 会员姓名
					name: monicker.value,
					// 会员编码
					username: encipher.value,
					// 工会code
					unionCode: Hcode.value
				}
				console.log(data)
				HYtotal(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}



			// 搜索加载
			const formative = ref(false)
			//   onBeforeRouteLeave((to,from)=>{
			//     console.log(to)
			//     console.log(from)
			//  })
			const route = useRoute();
			// 关闭页面
			let selectIt = ref()
			// 下拉隐藏
			let closeIt = () => {
				selectIt.value.blur();
			}
			// 获取code 
			let Hcode = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			// 弹窗是否显示
			let visible = ref(false);
			// 搜索选择所属组织
			let multiple = () => {
				visible.value = true
			}
			// 单元格点击 跳转
			const unit = (row) => {
				console.log(row)
				sessionStorage.setItem('userids', row)
				router.push({
					name: 'Hydetails',
					params: {
						UserId: 1
					}
				});
			}
			// 会员姓名输入框改变
			// const orangutan=()=>{
			//   if(monicker.value==''&&encipher.value==''&&ability.value==''){
			//     list()
			//   }
			//   console.log(monicker.value)
			// }
			// 会员编号输入框改变值触发
			// const legs=()=>{
			//   if(monicker.value==''&&encipher.value==''&&ability.value==''){
			//     list()
			//   }
			// }
			// 选择组织名称变化
			let numerical = () => {
				// if(monicker.value==''&&encipher.value==''&&ability.value==''){
				//   list()
				// }
				Hcode.value = ''
			}
			// 搜索框
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			const lang = () => {
				Zzlist().then((res) => {
					console.log(res)
					texture.value = res.data.data.wholeUnionList
				})
			}
			// 组织名称
			let ability = ref('')
			// 编码
			let encipher = ref('')
			// 姓名
			let monicker = ref('')
			let search1 = () => {
				formative.value = true
				search()
			}
			const search = () => {
				texts.value = "加载中"
				console.log(monicker.value)
				console.log(encipher.value)
				console.log(ability.value)
				let data = {
					limit: branches.value,
					page: currentPage.value,
					name: monicker.value,
					username: encipher.value,
					unionCode: Hcode.value
				}
				Hylist(qs.stringify(data)).then((res) => {
					console.log(res)
					formative.value = false
					altogether.value = res.data.data.page.totalCount
					filterTableData.value = res.data.data.page.list
					if (filterTableData.value.length == 0) {
						texts.value = "暂无数据"
					}
				})
			}
			// 离职归档弹窗
			const remove = ref(false)
			// 离职归档
			const affirm = () => {
				let data = {
					userId: id.value
				}
				Eonhole(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						ElMessage({
							message: "成功",
							type: "success",
							center: true,
						});
						if (monicker.value !== '' || encipher.value !== '' || ability.value !== "") {
							search()
						} else {
							list()
						}
						remove.value = false
					} else {
						ElMessage({
							message: res.data.msg,
							type: "warning",
							center: true,
						});
						if (monicker.value !== '' || encipher.value !== '' || ability.value !== "") {
							search()
						} else {
							list()
						}
						remove.value = false
					}
				})
			}
			let id = ref('')
			const expurgate = (o) => {
				remove.value = true
				id.value = o
				console.log(o)
			}
			// 会员管理列表内容
			let texts = ref('加载中')
			// 分页
			// 每页显示条数
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				pagesize.value = size
				branches.value = size
				if (monicker.value !== '' || encipher.value !== '' || ability.value !== "") {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				if (monicker.value !== '' || encipher.value !== '' || ability.value !== "") {
					search()
				} else {
					list()
				}
			}
			// 会员列表接口
			// 每页加载条数
			let branches = ref(6)
			// 数据总共条数
			let altogether = ref(null)
			const list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value
				}
				Hylist(qs.stringify(data)).then((res) => {
					console.log(res)
					altogether.value = res.data.data.page.totalCount
					filterTableData.value = res.data.data.page.list
					if (filterTableData.value.length == 0) {
						texts.value = "暂无数据"
					}
				})
			}
			onDeactivated(() => {
				// if(sessionStorage.getItem('delItem')==sessionStorage.getItem('delItemASD')){
				//   Hcode.value=''
				//   ability.value=''
				//   encipher.value=''
				//   monicker.value=''
				//   branches.value=6
				//   currentPage.value=1
				//   pagesize.value=6
				//   list()
				// }
				console.log('关闭了')
			})
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				if (translate.value == 1) {
					Hcode.value = ''
					ability.value = ''
					encipher.value = ''
					monicker.value = ''
					texts.value = '加载中'
					// branches.value = 6
					// currentPage.value = 1
					list()
					lang()
				}
				console.log(translate.value)
				// console.log('总是触发')
			})
			onMounted(() => {
				// route.meta.keepAlive=true
				//   router.options.routes[1].children.forEach((item)=>{
				//     if(route.path==item.path){
				//       item.meta.keepAlive=true
				//       console.log(item)
				//     }
				// })
				console.log(route.path)
				list()
				lang()
			})
			// 编辑跳转
			const copyreader = (o) => {
				console.log(o)
				// router.push('/hyedit')
				router.push({
					name: 'hyedit',
					params: {
						UserId: 1
					}
				});
				sessionStorage.setItem('user', o)
			}
			// 路由跳转
			const router = useRouter()
			// 离职归档跳转
			let file = () => {
				router.push('/pigeonhole')
			}
			// 会员导入
			let guide = () => {
				router.push('/HYchannel')
			}
			// 头部添加跳转
			let append = () => {
				router.push('/hyadd')
			}
			// 列表数据循环
			const filterTableData = ref([])
			return {
				// 导出
				quoth,
				anew,
				payout,
				// 会员导入
				guide,
				// 搜索加载
				search1,
				formative,
				selectIt,
				closeIt,
				numerical,
				dendrogram,
				texture,
				defaultProps,
				// 弹窗是否显示
				visible,
				// 所属组织弹窗搜索
				multiple,
				unit,
				// orangutan,
				// legs,
				ability,
				encipher,
				search,
				monicker,
				file,
				affirm,
				// 离职归搭弹窗
				remove,
				// 离职归档
				expurgate,
				texts,
				// 总共条数
				altogether,
				// 每页显示条数
				handleSizeChange,
				handleCurrentChange,
				currentPage,
				pagesize,
				// 编辑跳转
				copyreader,
				// 头部组织添加跳转
				append,
				// 循环列表数据
				filterTableData
			};
		},
		components: {
			Delete,
			EditPen,
			Search,
			Plus
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.chart-card::-webkit-scrollbar {
		display: none;
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-scrollbar__thumb {
		display: none !important;
	}
</style>
<style>
	/* ::-webkit-scrollbar{display:none} */
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>